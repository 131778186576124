import React from "react"

import AppLogo from "../AppLogo"
import SidebarWrapper from "../SidebarWrapper"
import { externalLinks } from "../urls"

interface iNavSidebar {
  sidebarOpen: boolean
  onToggleSidebar: (open: boolean) => void
}

interface iNavbarLink {
  title: string
  href: string
}

const NavbarLink: React.FC<iNavbarLink> = ({ title, href }) => {
  return (
    <a
      href={href}
      referrerPolicy="no-referrer"
      className="no-underline site-text-blue text-xl"
    >
      <p className="flex items-center justify-between">
        <span>{title}</span>
      </p>
    </a>
  )
}

const NavSidebar: React.FC<iNavSidebar> = ({
  sidebarOpen,
  onToggleSidebar,
}) => {
  return (
    <SidebarWrapper sidebarOpen={sidebarOpen} onToggleSidebar={onToggleSidebar}>
      <div className="w-56 min-h-full overflow-x-auto overflow-y-auto flex items-center bg-white px-6 py-8">
        <div>
          <div className="flex items-center mb-8">
            <AppLogo width={56} />
            <p className="ml-2 text-gray-800 text-sm border-l pl-2">
              A data driven solution
            </p>
          </div>

          <h1 className="text-sm text-gray-600 border-b mb-2 pb-2">
            Applications
          </h1>
          <NavbarLink title="Lifestyle" href={externalLinks.lifestyleApp} />
          <div className="py-1" />
          <NavbarLink title="Outcomes" href={externalLinks.outcomesApp} />
          <p className="text-left text-gray-500 text-xs mt-8">
            Powered By
            <br />
            <a
              href={externalLinks.mchri}
              referrerPolicy="no-referrer"
              target="_blank"
            >
              Monash Centre for Health
              <br />
              Research &amp; Implementation
              <br />
              <strong>(MCHRI)</strong>
            </a>
          </p>
        </div>
      </div>
    </SidebarWrapper>
  )
}

export default NavSidebar
