import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { externalLinks } from "../urls"

export const MonashHealthLogo: React.FC<{ width: number }> = ({ width }) => {
  const style = { width }
  const gqlQueryAppLogo_FluidImage = graphql`
    query {
      mchriImage: file(relativePath: { eq: "monash-health-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  return (
    <StaticQuery query={gqlQueryAppLogo_FluidImage}>
      {data => (
        <a href={externalLinks.monashHealth} referrerPolicy={"no-referrer"} target="_blank">
          <Img
            style={style}
            alt={"Monash Health"}
            fluid={data.mchriImage.childImageSharp.fluid}
          />
        </a>
      )}
    </StaticQuery>
  )
}
