import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import React from "react"

interface iAppLogo {
  width: number
}

const query = graphql`
  query {
    appLogo: file(relativePath: { eq: "app-logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const AppLogo: React.FC<iAppLogo> = ({ width }) => {
  return (
    <StaticQuery query={query}>
      {data => (
        <>
          <div style={{ width: `${width}px` }}>
            <Img
              image={data.appLogo.childImageSharp.gatsbyImageData}
              alt="Personal GDM logo"
            />
          </div>
        </>
      )}
    </StaticQuery>
  )
}

export default AppLogo
