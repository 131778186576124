import moment from "moment"
import React, { useState } from "react"
import { FaFacebook } from "react-icons/fa"

import { BlueAppButton, FacebookAppButton } from "../AppButton/buttons"
import { MCHRILogo } from "../AppLogo/MCHRILogo"
import { MonashHealthLogo } from "../AppLogo/MonashHealthLogo"
import { MonashUniLogo } from "../AppLogo/MonashUniLogo"
import SiteContainer from "../SiteContainer"
import { externalLinks } from "../urls"
import SiteHeading from "../utils/SiteHeading"
import SiteParagraph from "../utils/SiteParagraph"

interface iFooter {
  show: boolean
}

const DisclaimerText = () => {
  const fullText = `Monash Center for Health Research and Implementation provides information, models, calculators, equations and algorithms (tools) intended for use 
                by healthcare professionals. These tools do not give professional advice; physicians and other 
                healthcare professionals who use these tools should exercise their own clinical judgment as to the
                information they provide. Consumers who use the tools do so at their own risk. Individuals with any 
                type of medical condition are specifically cautioned to seek professional medical advice before 
                beginning any sort of health treatment. For medical concerns, including decisions about medications 
                and other treatments, users should always consult their physician or other qualified healthcare 
                professional. The contents of the Monash Center for Health Research and Implementation Site, such as text, graphics and images are for informational
                purposes only. Monash Center for Health Research and Implementation does not recommend or endorse any specific tests, physicians, products, procedures,
                opinions, or other information that may be mentioned on the Site.
                Monash Center for Health Research and Implementation explicitly does not warrant the accuracy of the information contained on this site.
                Monash Center for Health Research and Implementation does not give medical advice, nor do we provide medical or diagnostic services. Medical
                information changes rapidly. Neither we nor our authors guarantee that the content covers all possible 
                uses, directions, precautions, drug interactions, or adverse effects that may be associated with any 
                therapeutic treatments.
                Your reliance upon information and content obtained by you at or through this site is solely at your 
                own risk. Neither we nor our authors assume any liability or responsibility for damage or injury 
                (including death) to you, other persons or property arising from any use of any product, information, 
                idea or instruction contained in the content or services provided to you.
                Last revised: August 2020.
                `

  const shortText = `Monash Center for Health Research and Implementation provides information, models, calculators, equations and
                    algorithms (tools) intended for use by healthcare professionals. These
                    tools do not give professional advice; physicians and other healthcare
                    professionals who use these tools should exercise their own clinical
                    judgment as to the information they provide.`

  const [showFullText, setShowFullText] = useState(false)

  if (showFullText)
    return (
      <>
        <span>{fullText}</span>&nbsp;
        <span
          className="underline cursor-pointer"
          onClick={() => setShowFullText(false)}
        >
          Read Less
        </span>
      </>
    )

  return (
    <>
      <span>{shortText}</span>&nbsp;
      <span
        className="underline cursor-pointer"
        onClick={() => setShowFullText(true)}
      >
        Read More
      </span>
    </>
  )
}

const FooterPanel_FooterConclusion = () => {
  return (
    <div className="text-center sm:text-left col-span-1 lg:col-span-2">
      <SiteHeading className="text-4xl" colorClassName="text-white">
        Personal GDM
      </SiteHeading>
      <SiteParagraph colorClassName="text-white">
        A web-based, data-driven prediction tool
      </SiteParagraph>
      <br />
      <SiteParagraph className="text-xs" colorClassName="text-white">
        <strong>Disclaimer:&nbsp;</strong>
        <DisclaimerText />
      </SiteParagraph>
      <br />
      <SiteParagraph colorClassName="text-white" className="text-xs">
        All rights reserved. &copy; {moment().year()}
      </SiteParagraph>
    </div>
  )
}

const FooterPanel_Logos = () => {
  return (
    <div>
      <h1 className="text-white text-lg hidden md:block mt-5 mb-5">
        Powered by
      </h1>
      <div className="flex justify-center md:justify-start">
        <div className="flex items-center flex-wrap justify-center md:justify-start ">
          <div
            className="rounded-lg flex items-center justify-center mb-2 mr-2"
            style={{
              backgroundColor: "white",
              height: "70px",
              width: "120px",
            }}
          >
            <MCHRILogo width={100} />
            <div className="mx-1" />
          </div>

          <div
            className="rounded-lg flex items-center justify-center mb-2"
            style={{
              backgroundColor: "white",
              height: "70px",
              width: "120px",
            }}
          >
            <MonashHealthLogo width={100} />
          </div>
          <div className="mx-1" />
          <div
            className="rounded-lg flex items-center justify-center mb-2"
            style={{
              backgroundColor: "white",
              height: "70px",
              width: "120px",
            }}
          >
            <MonashUniLogo width={100} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Footer: React.FC<iFooter> = ({ show }) => {
  if (!show) return null
  return (
    <div className="site-bg-blue">
      <SiteContainer>
        <div className="py-12">
          <div className="grid  grid-cols-1 lg:grid-cols-3 gap-10">
            <FooterPanel_FooterConclusion />
            <FooterPanel_Logos />
          </div>
        </div>
      </SiteContainer>
    </div>
  )
}

export default Footer
