import React from "react"

import { iAppButton } from "./index"

const applyClassName = (
  Button: React.FC<iAppButton>,
  classNameToApply: string
) => {
  return ({ className, ...rest }: iAppButton) => {
    return <Button className={`${className} ${classNameToApply}`} {...rest} />
  }
}

export default applyClassName
