import React from "react"

import applyClassName from "./applyClassName"
import AppButton from "./index"

export const RoundedAppButton = applyClassName(
  AppButton,
  "rounded-full px-4 py-1 transition duration-100 ease-in"
)
export const DarkAppButton = applyClassName(
  RoundedAppButton,
  "site-bg-blue text-white hover:bg-blue-900"
)
export const WhiteAppButton = applyClassName(
  RoundedAppButton,
  "bg-white text-blue-900 hover:bg-gray-100"
)

export const BlueAppButton = applyClassName(
  RoundedAppButton,
  "bg-blue-900 text-white hover:bg-blue-800 text-sm"
)


export const FacebookAppButton = applyClassName(
  RoundedAppButton,
  "bg-blue-600 text-white hover:bg-blue-700 text-sm"
)

