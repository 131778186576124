import React from "react"

const SiteContainer: React.FC<{
  children: object
  className?: string
  [id: string]: any
}> = ({ children, className, ...rest }) => {
  return (
    <div className={[className, "px-4 sm:px-12 xl:px-16"].join(" ")} {...rest}>
      {children}
    </div>
  )
}

export default SiteContainer
