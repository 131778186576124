import React from "react"

const SiteHeading: React.FC<{
  children: any
  className?: string
  colorClassName?: string
  [id: string]: any
}> = ({ children, className, colorClassName, ...rest }) => {
  const localClassName = "text-4xl sm:text-5xl font-bold"
  const textColorClassName = colorClassName || "text-site-blue"
  return (
    <h1
      className={[className, textColorClassName, localClassName].join(" ")}
      {...rest}
    >
      {children}
    </h1>
  )
}

export default SiteHeading
