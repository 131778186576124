import React from "react"

const SiteParagraph: React.FC<{
  children: any
  className?: string
  colorClassName?: string
  [id: string]: any
}> = ({ children, className, colorClassName, ...rest }) => {
  const localClassName = ""
  const textColorClassName = colorClassName || "site-text-blue"
  return (
    <p
      className={[className, textColorClassName, localClassName].join(" ")}
      {...rest}
    >
      {children}
    </p>
  )
}

export default SiteParagraph
