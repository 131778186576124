import React, { useState } from "react"
import { FaBars } from "react-icons/fa"

import { WhiteAppButton } from "../AppButton/buttons"
import AppLogo from "../AppLogo"
import SiteContainer from "../SiteContainer"
import { externalLinks } from "../urls"
import NavSidebar from "./NavSidebar"

const DesktopNavbarLink: React.FC<{
  title: string
  href: string
}> = ({ title, href }) => {
  return (
    <div>
      <a
        href={href}
        referrerPolicy="no-referrer"
        className="site-text-blue text-sm"
        style={{ textDecoration: "none !important" }}
      >
        <p className="flex items-center justify-between px-3 hover:bg-gray-100 py-1 rounded-full">
          <span>{title}</span>
        </p>
      </a>
    </div>
  )
}

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const onToggleSidebar = (open: boolean) => setSidebarOpen(open)
  return (
    <>
      <nav className="sticky top-0 w-full shadow bg-white z-10">
        <SiteContainer>
          <div className="flex items-center justify-between py-2 sm:py-3">
            <div className="flex items-center">
              <div>
                <AppLogo width={50} />
              </div>
              <h1 className="text-sm sm:text-base pl-2 ml-2 py-1 border-l site-text-blue">
                Personal GDM
              </h1>
            </div>
            <div>
              <div className="block sm:hidden">
                <WhiteAppButton
                  title="Menu"
                  Icon={<FaBars />}
                  onClick={() => onToggleSidebar(true)}
                />
              </div>
              <div className="hidden sm:flex items-center">
                <DesktopNavbarLink
                  title="Lifestyle"
                  href={externalLinks.lifestyleApp}
                />
                <div className="px-2" />
                <DesktopNavbarLink
                  title="Outcomes"
                  href={externalLinks.outcomesApp}
                />
              </div>
            </div>
          </div>
        </SiteContainer>
      </nav>
      <NavSidebar sidebarOpen={sidebarOpen} onToggleSidebar={onToggleSidebar} />
    </>
  )
}

export default Navbar
