import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import { externalLinks } from "../urls"

export const MonashUniLogo: React.FC<{ width: number }> = ({ width }) => {
  const style = { width }
  const gqlQueryAppLogo_FluidImage = graphql`
    query {
      mchriImage: file(relativePath: { eq: "monash-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  return (
    <StaticQuery query={gqlQueryAppLogo_FluidImage}>
      {data => (
        <a href={externalLinks.MonashUniversity} target="_blank">
          <Img
            style={style}
            alt={"Monash University"}
            fluid={data.mchriImage.childImageSharp.fluid}
          />
        </a>
      )}
    </StaticQuery>
  )
}
