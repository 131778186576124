/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"

import Navbar from "./Navbar"
import "./layout.css"
import Footer from "./Footer"

const Layout: React.FC<{ children: any }> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer show={true}/>
    </>
  )
}

export default Layout
