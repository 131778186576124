import React from "react"
import SideBar from "react-sidebar"

interface iSidebarWrapper {
  children: any
  sidebarOpen: boolean
  onToggleSidebar: (open: boolean) => void
}

const SidebarWrapper: React.FC<iSidebarWrapper> = ({
  children,
  sidebarOpen,
  onToggleSidebar,
}) => {
  return (
    <>
      <div className="hidden sm:block">
        <SideBar
          sidebar={children}
          open={sidebarOpen}
          onSetOpen={onToggleSidebar}
          children={<div />} // This is just a props requirement
          shadow={true}
          pullRight
          styles={{
            sidebar: {
              position: "fixed",
              zIndex: "1000",
            },
            root: {
              position: "undefined",
            },
            content: {
              position: "undefined",
              top: "undefined",
              left: "undefined",
              right: "undefined",
              bottom: "undefined",
            },
            overlay: {
              zIndex: "5",
            },
          }}
        />
      </div>
      <div className="block sm:hidden">
        <SideBar
          sidebar={children}
          open={sidebarOpen}
          onSetOpen={onToggleSidebar}
          children={<div />} // This is just a props requirement
          shadow={true}
          styles={{
            sidebar: {
              position: "fixed",
              zIndex: "1000",
            },
            root: {
              position: "undefined",
            },
            content: {
              position: "undefined",
              top: "undefined",
              left: "undefined",
              right: "undefined",
              bottom: "undefined",
            },
            overlay: {
              zIndex: "5",
            },
          }}
        />
      </div>
    </>
  )
}

export default SidebarWrapper
