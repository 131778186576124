import React from "react"

export interface iAppButton {
  title: string
  className?: string
  iconOnRight?: boolean
  Icon?: any
  onClick?: () => void
  toolTip?: string
  [id: string]: any
}

const AppButton: React.FC<iAppButton> = ({
  title,
  className,
  iconOnRight,
  Icon,
  onClick,
  toolTip,
  ...rest
}) => {
  return (
    <button
      title={toolTip || title}
      className={`${className}`}
      onClick={onClick}
      {...rest}
    >
      <span className="flex items-center leading-none py-1">
        {Boolean(Icon) && !iconOnRight && <span className="mr-3">{Icon}</span>}
        <span>{title}</span>
        {Boolean(Icon) && iconOnRight && <span className="ml-3">{Icon}</span>}
      </span>
    </button>
  )
}

export default AppButton
